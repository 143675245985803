<!-- perMobileFlash -->
<template>
	<div class="flash_news">
		<router-link
			target="_blank"
			:to="{ name: '/kuaixun_wz', params: { id: item.id } }"
		>
			<div class="flex flex_content">
				<img
					class="flash_flag"
					src="https://res.metaera.hk/resources/assets/images/zt/flash_flag.svg"
				/>
				<div class="flash_title">
					{{
						localLanguage == 2
							? item.title
								? item.title
								: item.title
							: item.title
					}}
				</div>
			</div>
			<div class="flash_time">{{ item.release_time }}</div>
		</router-link>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";

export default {
	props: ["item"],
	data() {
		return {
			localLanguage: 1,
		};
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
};
</script>

<style scoped>
.flash_news {
	padding: 12px 0;
}
.flex_content {
	display: flex;
	align-items: baseline;
}
.flash_flag {
	margin-right: 8px;
	width: 10px;
}
.flash_title {
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	letter-spacing: 0em;
	/* 纯白 */
	color: #fff;
}
.flash_time {
	margin-top: 10px;
	font-size: 12px;
	font-weight: normal;
	line-height: 1;
	/* 字段三级 */
	color: #b2b2b2;
}
</style>
