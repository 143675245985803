<template>
	<div class="topic-container">
		<div class="banner-box">
			<div class="banner-img">
				<TopSwiper type="4"></TopSwiper>
			</div>
			<!-- <img
				class="banner-img"
				src="https://res.metaera.hk/resources/assets/images/zt/zt_banner_400.jpeg"
			/> -->
		</div>
		<div class="zhuanti_box padding_120 flex justify_content_sb">
			<div class="MainLeft_760 mobile_box">
				<div
					class="zhuanti_news mobile_padding_15"
					v-if="hotRecommendList.length > 0"
				>
					<div class="topTitle"
						><span :class="{ maincolor: !isMobileDevice }">热门专题</span></div
					>
					<div class="topBox">
						<!-- 移动端的样式 -->
						<template v-if="isMobileDevice">
							<div v-for="(items, index) in hotRecommendList" :key="index">
								<!-- 1是文章，2是快讯 -->
								<PerMobileNews
									:item="items"
									v-if="items.type == 1"
								></PerMobileNews>
								<perMobileFlash :item="items"></perMobileFlash>
							</div>
							<!-- <more @infinite="loadMore" v-if="tag"></more> -->
						</template>
						<!-- pc端的样式 -->
						<template v-else>
							<wzitem
								v-for="(items, index) in hotRecommendList"
								:item="items"
								:key="index"
							></wzitem>
						</template>
					</div>
				</div>
				<div>
					<hotTopics title="近期专题"></hotTopics>
				</div>
			</div>
			<div class="MainRight_400" v-if="!isMobileDevice">
				<module-part type="1"> </module-part>
				<module-part type="4"> </module-part>
				<module-part type="5"> </module-part>
			</div>
		</div>
	</div>
</template>
<script>
import { dexRecommendList } from "@/api/home";
import wzitem from "@/components/pc/wzitem.vue";
import modulePart from "@/components/modulePart.vue";
import hotTopics from "@/components/hotTopics.vue";
import TopSwiper from "@/components/topSwiper";
import PerMobileNews from "@/components/mobile/perMobileNews";
import perMobileFlash from "@/components/mobile/perMobileFlash";
export default {
	name: "topic",
	components: {
		wzitem,
		modulePart,
		hotTopics,
		TopSwiper,
		PerMobileNews,
		perMobileFlash,
		page: 1,
		size: 25,
		tag: false,
	},
	data() {
		return {
			hotRecommendList: [],
		};
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	created() {
		this.getDexRecommendList();
	},
	methods: {
		loadMore() {
			this.page++;
			this.getDexRecommendList();
		},
		// 获取精选快讯
		getDexRecommendList() {
			// let res = JSON.parse(sessionStorage.getItem("homeflashList"));
			// if (res != null && res.code == 200) {
			// 	this.hotTopicsList = res.data.hotLists;
			// }
			dexRecommendList({ page: this.page, size: this.size })
				.then((res) => {
					// sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						this.hotRecommendList = this.hotRecommendList.concat(res.data);
						res.data.length < this.size
							? (this.tag = false)
							: (this.tag = true);
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.banner-box {
	width: 100%;
	height: clamp(200px, calc(100vw * 400 / 1440), 400px);
	padding-top: clamp(20px, calc(100vw * 30 / 1440), 30px);
	padding-bottom: clamp(20px, calc(100vw * 30 / 1440), 30px);
	background-image: url(https://res.metaera.hk/resources/assets/images/jianbian.png);
	background-size: cover;
	background-position: center;
	overflow: hidden;
}
.banner-img {
	display: block;
	margin: 0 auto;
	border-radius: 20px;
	width: clamp(200px, calc(100vw * 1200 / 1440), 1200px);
	height: clamp(200px, calc(100vw * 400 / 1440), 400px);
}
.zhuanti_box {
	padding-top: clamp(30px, calc(100vw * 40 / 1440), 40px);
}
.zhuanti_news {
	margin-bottom: clamp(30px, calc(100vw * 50 / 1440), 50px);
}
.topTitle {
	box-sizing: border-box;
	height: calc(100vw * 40 / 1440);
	padding-bottom: clamp(16px, calc(100vw * 20 / 1440), 20px);
	border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.topTitle > span {
	font-size: clamp(16px, calc(100vw * 20 / 1440), 20px);
	line-height: 1;
	font-weight: 900;
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.banner-box,
	.banner-img {
		width: 100%;
		height: auto;
	}
	.banner-box {
		padding: 15px calc(100vw * 15 / 375);
		box-sizing: border-box;
		background-color: #1c1c1e;
		background-image: none;
	}
	.topTitle {
		border: 0;
		padding-top: 20px;
	}
	.topTitle > span {
		font-size: 18px;
		line-height: 1;
		font-weight: 600;
		color: #fff;
	}
}
</style>
