<template>
	<div class="swiperBox pointer swiperBoxHome">
		<!-- -->

		<el-carousel
			:interval="3000"
			:indicator-position="bnnerLists.length > 1 ? '' : 'none'"
			:arrow="bnnerLists.length > 1 ? 'hover' : 'never'"
		>
			<el-carousel-item v-for="(item, index) in bnnerLists" :key="index">
				<a target="_blank" :href="item.url">
					<img
						:src="item.pic"
						:key="item.pic"
						style="object-fit: cover"
						alt=""
					/>
				</a>
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
import { newBus } from "@/components/pc/topHeader2.vue";
import { bannerInfo } from "@/api/home";
export default {
	props: ["type", "bottomLists"],
	data() {
		return {
			bnnerLists: [],
			localLanguage: 1,
		};
	},
	created() {
		this.getBannerInfo();
	},
	mounted() {
		newBus.$on("global-notification", (data) => {
			let localLanguage = sessionStorage.getItem("localLanguage");
			if (localLanguage == "繁体中文") {
				this.localLanguage = 1;
			} else if (localLanguage == "english") {
				this.localLanguage = 2;
			} else {
				this.localLanguage = 0;
			}
		});
		let localLanguage = sessionStorage.getItem("localLanguage");
		if (localLanguage == "繁体中文") {
			this.localLanguage = 1;
		} else if (localLanguage == "english") {
			this.localLanguage = 2;
		} else {
			this.localLanguage = 0;
		}
	},
	methods: {
		getBannerInfo() {
			bannerInfo({ type: this.type })
				.then((res) => {
					this.bnnerLists = res.data.list;
					console.log("首页获取banner数据", res);
				})
				.catch((error) => {
					// this.$message.error(error.message);
				});
		},
	},
};
</script>

<style scoped>
.swiperBox {
	width: 100%;
	/* height: clamp(200px, calc(100vw * 5 / 18), 400px); */
	aspect-ratio: 3450/1400;
	background: white;
	border-radius: 15px;
}
.el-carousel--horizontal {
	height: 100%;
}

.swiperBox img {
	width: 100%;
	height: 100%;
	border-radius: 15px;
}

.swiperBox_bottom {
	width: 100%;
	height: 131px;
	border-radius: 15px;
	flex-direction: row;
	display: flex;
}

.swiperBox_bottom_item {
	display: flex;
	align-items: center;
	margin-top: 37px;
	margin-left: 15px;
	margin-right: 24px;
	height: 60px;
	width: 50%;
}
.swiperBox_bottom_item_img {
	width: 97px;
	height: 60px;
	object-fit: cover;
}
.swiperBox_bottom_item_img img {
	width: 97px !important;
	height: 60px !important;
	object-fit: cover;
	border-radius: 8px !important;
}

.swiperBox_bottom_item_content {
	margin-left: 10px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
}
.swiperBox_bottom_item_title {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 14px;
	height: 41px;
	font-weight: normal;
	line-height: 20px;
	letter-spacing: 0em;

	/* 黑色主色（字段一级） */
	color: #333333;

	overflow: hidden;
	display: -webkit-box;
	display: -moz-box;
	text-overflow: ellipsis;
	-webkit-box-orient: vertical;
	box-orient: vertical;
	-webkit-line-clamp: 2;
}

.swiperBox_bottom_item_time {
	font-family: "PingFang SC", "微软雅黑", "Helvetica Neue", "Hiragino Sans GB",
		"Microsoft YaHei", Arial, sans-serif;
	font-size: 12px;
	font-weight: normal;
	line-height: 12px;
	letter-spacing: 0em;
	margin-top: 5px;
	/* 字段三级 */
	color: #b2b2b2;
}

@media screen and (max-width: 900px) {
	.swiperBox {
		height: calc(100vw * 138 / 375);
		overflow: hidden;
	}
}
</style>
<style>
.swiperBoxHome .el-carousel__container {
	height: 100%;
	/* max-height: 240px !important; */
}
.swiperBoxHome .el-carousel__item {
	border-radius: 15px;
}
@media screen and (max-width: 900px) {
	.swiperBoxHome .el-carousel__container,
	.swiperBoxHome .el-carousel__item {
		height: calc(100vw * 138 / 375) !important;
	}
	.el-carousel__indicators--horizontal {
		display: none !important;
	}
}
</style>
