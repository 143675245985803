<template>
	<div class="eventWraper" v-if="hotTopicsList.length > 0">
		<div class="rsTitle rsTitleBg flex align_items_c justify_content_sb">
			<div class="rsTitleLeft flex align_items_c justify_content_sb">
				<div :class="{ maincolor: !isMobileDevice }">{{
					title ? title : "热门专题"
				}}</div>
				<div
					v-if="!title"
					class="flex align_items_c pointer rsTitleLeft_more"
					@click="toPartPage"
				>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
					<div class="rsTitleLeft_point"></div>
				</div>
			</div>
		</div>
		<div
			class="hot-topics hovercolor pointer"
			v-for="(item, index) in hotTopicsList"
			:key="index"
		>
			<!-- 移动端样式 -->
			<template v-if="isMobileDevice">
				<router-link
					target="_blank"
					:to="{ name: '/gw_dex', params: { id: item.id } }"
					class="flex align_items_c"
				>
					<div class="hot-name">#{{ item.name }}#</div>
					<div class="hot-info">
						<div class="contentNum">{{ item.count }}条内容</div>
						<div class="time">最近更新时间：{{ item.release_time }}</div>
					</div>
				</router-link>
			</template>
			<!-- pc端样式 -->
			<template v-else>
				<router-link
					target="_blank"
					:to="{ name: '/gw_dex', params: { id: item.id } }"
				>
					<div class="hot-name">#{{ item.name }}#</div>
					<div class="hot-info flex align_items_c">
						<div class="contentNum">{{ item.count }}条内容</div>
						<div class="time">最近更新时间：{{ item.release_time }}</div>
					</div>
				</router-link>
			</template>
		</div>
	</div>
</template>

<script>
import { hotTopicsList } from "@/api/home";
export default {
	name: "hotTopics",
	props: ["title"],
	data() {
		return {
			hotTopicsList: [],
		};
	},
	mounted() {
		this.getHotTopicsList();
	},
	computed: {
		isMobileDevice() {
			return this.$store.state.app.isMobileDevice;
		},
	},
	methods: {
		toPartPage() {
			this.$router.push({
				name: "/hot_topics",
			});
		},
		// 获取精选快讯
		getHotTopicsList() {
			// let res = JSON.parse(sessionStorage.getItem("homeflashList"));
			// if (res != null && res.code == 200) {
			// 	this.hotTopicsList = res.data.hotLists;
			// }
			const that = this;
			hotTopicsList({ size: 99 })
				.then((res) => {
					// sessionStorage.setItem("homeflashList", JSON.stringify(res));
					if (res.code == 200) {
						if (this.title) {
							that.hotTopicsList =
								res.data.length >= 20 ? res.data.slice(0, 20) : res.data;
						} else {
							that.hotTopicsList =
								res.data.length >= 5 ? res.data.slice(0, 5) : res.data;
						}
					}
				})
				.catch((error) => {});
		},
	},
};
</script>

<style scoped>
.hot-topics {
	margin-top: clamp(15px, calc(100vw * 20 / 1440), 20px);
	padding-bottom: 11px;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.hot-name {
	font-size: clamp(14px, calc(100vw * 16 / 1440), 16px);
	line-height: 1.2;
	font-weight: 500;
	color: #fff;
}
.hot-info {
	margin-top: 10px;
	color: #fff;
}

.contentNum,
.time {
	font-size: clamp(12px, calc(100vw * 12 / 1440), 14px);
	line-height: 1.7;
}
.contentNum {
	margin-right: clamp(40px, calc(100vw * 40 / 1440), 40px);
}
</style>
<style scoped>
@media screen and (max-width: 900px) {
	.rsTitle {
		border: none;
	}
	.rsTitleLeft div:nth-child(1) {
		font-size: 18px;
		line-height: 1;
		font-weight: 600;
		color: #fff;
	}
	.hot-name {
		margin-right: 25px;
	}
	.hot-info {
		margin-top: 0px;
	}
	.time {
		color: #b2b2b2;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1; /* 设置行数 */
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
</style>
